import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { EventOverview } from 'components/Events/EventOverview';
import { useGetFlaggedEventQuery } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { generateActionDescription } from 'components/Events/EventRow';

export function EventDetails() {
  const { messageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [OrgId] = useOrgId();

  // Prevent body scrolling when overlay is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const { data: eventDetails, isLoading } = useGetFlaggedEventQuery(
    { messageId: messageId || '', organizationId: OrgId },
    {
      skip: !messageId,
    }
  );

  const handleClose = () => {
    // Navigate back to the previous route, or to / if no previous state
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate('/');
    }
  };

  return (
    <EventOverview
      open
      event={eventDetails}
      onClose={handleClose}
      generateActionDescription={generateActionDescription}
      isLoading={isLoading}
    />
  );
}
