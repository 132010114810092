export const remediationActionDetails: Record<string, { text: string[] }> = {
  MOVE_TO_JUNK: {
    text: ['Moved To Junk'],
  },
  QUARANTINE_MESSAGE: {
    text: ['In Quarantine'],
  },
  FORCE_PASSWORD_CHANGE: {
    text: ['Force Logout', 'Reset Password'],
  },
};
