interface ValidationTileProps {
  text: string;
  icon: React.ReactNode;
  bgColor: string;
}

export function ValidationTile({ text, icon, bgColor }: ValidationTileProps) {
  return (
    <button
      className={`flex-1 py-3 px-4 flex items-center gap-4 rounded-lg border-light border-border-primary bg-${bgColor}`}
      type="button"
    >
      <div>{icon}</div>
      <span className="text-black text-base">{text}</span>
    </button>
  );
}
