import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useOrgId } from 'hooks/useOrgId';
import { useGetBlacklistEventQuery } from 'service/eventsApi';
import { BlacklistOverview } from 'components/Remediation/BlacklistOverview';

export function BlacklistEventDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Prevent body scrolling when overlay is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleClose = () => {
    navigate('/remediation?tab=blocked');
  };

  const [orgId] = useOrgId();

  const { data, isLoading } = useGetBlacklistEventQuery(
    {
      orgId,
      messageId: id || '',
    },
    {
      skip: !id,
    }
  );

  return <BlacklistOverview open event={data} isLoading={isLoading} onClose={handleClose} />;
}
