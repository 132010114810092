import { ListRecord } from 'models/rules';
import { ReactComponent as IconBlocklist } from 'assets/icons/blocklist.svg';
import { ReactComponent as IconAllowlist } from 'assets/icons/allowlist.svg';

import { Divider } from 'components/Divider';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

interface RulesConfirmationModalProps {
  lists: ListRecord[];
  onClose: () => void;
  onSubmit: () => void;
  ruleType: 'blacklist' | 'allowlist';
}
export function RulesConfirmationModal({
  lists,
  onClose,
  onSubmit,
  ruleType,
}: RulesConfirmationModalProps) {
  return (
    <div className="relative flex flex-col items-center w-full">
      <button
        type="button"
        className="w-6 h-6 text-center rounded-full border-light border-border-primary flex items-center justify-center absolute right-4 top-4"
        onClick={() => onClose()}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <IconClose className="w-2.5 h-2.5 fill-light-grey" />
      </button>
      <div className="flex flex-col items-center gap-8 px-8 py-6 ">
        <div className="flex flex-col items-center gap-5">
          {ruleType === 'blacklist' ? (
            <IconBlocklist className="w-25 h-25" />
          ) : (
            <IconAllowlist className="w-25 h-25" />
          )}
          <span className="text-xl text-center">
            Are you sure you want to add the following address to the{' '}
            {ruleType === 'allowlist' ? 'Allow' : 'Block'} list?
          </span>
          <span className="text-light-grey text-center">
            {' '}
            {ruleType === 'allowlist'
              ? "Any mail from these accounts will be allowed in the user's inbox and will not be checked for threats"
              : 'Any future mail from these accounts will be automatically Quarantined.'}
            .
          </span>
        </div>
        <div className="flex flex-col gap-4">
          {lists.map((list) => (
            <button
              type="button"
              className="bg-soft-gray border-light border-border-primary rounded-lg px-4 py-2"
            >
              {list.value}
            </button>
          ))}
        </div>
      </div>
      <Divider customCss="my-6 w-full" />
      <div className="flex flex-col gap-4 w-full items-center px-8 py-6">
        <button
          onClick={() => onSubmit()}
          type="button"
          className="w-4/5 rounded-lg bg-black text-white py-4"
        >
          Confirm
        </button>
        <button
          onClick={() => onClose()}
          type="button"
          className="w-fit border-b border-b-light-grey"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
