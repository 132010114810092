import { createApi } from '@reduxjs/toolkit/query/react';
import { USERS_URL, LOGOUT_USER_URL, ADMINS_URL } from 'constants/apiUrls';

import {
  GetUsers,
  GetUsersAPI,
  GetUsersFilter,
  mapUser,
  VIPResponse,
  FinanceAccResponse,
} from 'models/user';
import {
  AddAdminRequest,
  RemoveAdminRequest,
  RemoveAdminResponse,
  AddAdminResponse,
  GetAdmins,
} from 'models/admin';

import { updateCount, addSelectedUser } from 'redux-store/slices/users';

import { baseQuery } from './baseQuery';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQuery,
  tagTypes: ['USERS', 'VIP', 'ADMIN', 'FINANCE_AND_ACCOUNTING'],
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsers, GetUsersFilter & { orgId: string }>({
      query: ({ limit, offset, searchQuery, orgId }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());
        if (searchQuery) params.append('query', searchQuery);

        return `${USERS_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['USERS'],
      transformResponse: (response: GetUsersAPI) => {
        const transformedResponse: GetUsers = {
          hits: response?.hits?.map((user) => mapUser(user)) || [],
          total: response.total,
          limit: response.limit,
          offset: response.offset,
        };

        return transformedResponse;
      },
      async onQueryStarted({ searchQuery }, { queryFulfilled, dispatch }) {
        const { data } = await queryFulfilled;
        if (searchQuery === '') {
          dispatch(updateCount(data?.total));
        }
        dispatch(addSelectedUser(data?.hits[0]));
      },
    }),
    forceLogout: builder.mutation<null, { orgId: string; employeeId: string; messageId?: string }>({
      query: ({ orgId, employeeId, messageId }) => ({
        url: LOGOUT_USER_URL(orgId, employeeId),
        method: 'POST',
        body: {
          messageId: messageId || null,
        },
      }),
    }),
    getVIPUsers: builder.query<GetUsers, GetUsersFilter & { orgId: string }>({
      query: ({ limit, offset, searchQuery, orgId }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());
        if (searchQuery) params.append('query', searchQuery);
        params.append('vip', 'true');

        return `${USERS_URL(orgId)}?${params.toString()}`;
      },
      transformResponse: (response: GetUsersAPI) => {
        const transformedResponse: GetUsers = {
          hits: response?.hits?.map((user) => mapUser(user)) || [],
          total: response.total,
          limit: response.limit,
          offset: response.offset,
        };

        return transformedResponse;
      },
      providesTags: ['VIP'],
    }),
    addVIPUsers: builder.mutation<VIPResponse, { orgId: string; userIds: string[] }>({
      query: ({ orgId, userIds }) => ({
        url: `${USERS_URL(orgId)}/vip`,
        method: 'POST',
        body: {
          userIds: userIds,
        },
      }),
      invalidatesTags: ['VIP', 'USERS'],
    }),
    removeVIPUsers: builder.mutation<VIPResponse, { orgId: string; userIds: string[] }>({
      query: ({ orgId, userIds }) => ({
        url: `${USERS_URL(orgId)}/vip`,
        method: 'DELETE',
        body: {
          userIds: userIds,
        },
      }),
      invalidatesTags: ['VIP', 'USERS'],
    }),
    getFinancialAndAccountingUsers: builder.query<GetUsers, GetUsersFilter & { orgId: string }>({
      query: ({ limit, offset, searchQuery, orgId }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());
        if (searchQuery) params.append('query', searchQuery);
        params.append('finance-accounting', 'true');

        return `${USERS_URL(orgId)}?${params.toString()}`;
      },
      transformResponse: (response: GetUsersAPI) => {
        const transformedResponse: GetUsers = {
          hits: response?.hits?.map((user) => mapUser(user)) || [],
          total: response.total,
          limit: response.limit,
          offset: response.offset,
        };

        return transformedResponse;
      },
      providesTags: ['FINANCE_AND_ACCOUNTING'],
    }),
    addFinanceAndAccUsers: builder.mutation<
      FinanceAccResponse,
      { orgId: string; userIds: string[] }
    >({
      query: ({ orgId, userIds }) => ({
        url: `${USERS_URL(orgId)}/finance-accounting`,
        method: 'POST',
        body: {
          userIds: userIds,
        },
      }),
      invalidatesTags: ['FINANCE_AND_ACCOUNTING', 'USERS'],
    }),
    removeFinanceAndAccUsers: builder.mutation<
      FinanceAccResponse,
      { orgId: string; userIds: string[] }
    >({
      query: ({ orgId, userIds }) => ({
        url: `${USERS_URL(orgId)}/finance-accounting`,
        method: 'DELETE',
        body: {
          userIds: userIds,
        },
      }),
      invalidatesTags: ['FINANCE_AND_ACCOUNTING', 'USERS'],
    }),
    getAdmins: builder.query<
      GetAdmins,
      {
        limit: number;
        offset: number;
        orgId: string;
      }
    >({
      query: ({ limit, offset, orgId }) => {
        const params = new URLSearchParams();
        if (limit) params.append('limit', limit.toString());
        if (offset >= 0) params.append('offset', offset.toString());

        return `${ADMINS_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['ADMIN'],
    }),
    addAdmins: builder.mutation<AddAdminResponse, AddAdminRequest & { orgId: string }>({
      query: ({ admins, orgId }) => ({
        url: `${ADMINS_URL(orgId)}/add`,
        method: 'POST',
        body: admins.map((admin) => ({
          adminRole: admin.role,
          employeeId: admin.employeeId,
        })),
      }),
      invalidatesTags: ['ADMIN', 'USERS'],
    }),
    removeAdmins: builder.mutation<RemoveAdminResponse, RemoveAdminRequest & { orgId: string }>({
      query: ({ adminIds, orgId }) => ({
        url: `${ADMINS_URL(orgId)}/remove`,
        method: 'POST',
        body: {
          adminIds: adminIds,
        },
      }),
      invalidatesTags: ['ADMIN', 'USERS'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useForceLogoutMutation,
  useGetVIPUsersQuery,
  useAddVIPUsersMutation,
  useRemoveVIPUsersMutation,
  useAddFinanceAndAccUsersMutation,
  useRemoveFinanceAndAccUsersMutation,
  useGetAdminsQuery,
  useAddAdminsMutation,
  useRemoveAdminsMutation,
} = usersApi;
