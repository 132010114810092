export function validateDomain(domain: string): boolean {
  const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
}

export type ValidationResult = {
  isValid: boolean;
  error: string;
};

// IPv4 validation
export function validateIPv4(input: string): ValidationResult {
  const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  const trimmedInput = input.trim();

  if (!ipv4Pattern.test(trimmedInput)) {
    return { isValid: false, error: 'Invalid IPv4 format' };
  }

  const octets = trimmedInput.split('.');
  const validOctets = octets.every((octet) => {
    const num = parseInt(octet, 10);
    return num >= 0 && num <= 255;
  });

  return validOctets
    ? { isValid: true, error: '' }
    : { isValid: false, error: 'Invalid IPv4 octet values' };
}

// Email validation
export function validateEmail(input: string): ValidationResult {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const trimmedInput = input.trim();

  if (!emailPattern.test(trimmedInput)) {
    return { isValid: false, error: 'Invalid email format' };
  }

  const [localPart, domain] = trimmedInput.split('@');
  if (localPart.length > 64 || domain.length > 255) {
    return { isValid: false, error: 'Email part exceeds length limit' };
  }

  return { isValid: true, error: '' };
}

// Domain validation
export function validateDomains(input: string): ValidationResult {
  const domainPattern = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  const trimmedInput = input.trim();

  if (!domainPattern.test(trimmedInput)) {
    return { isValid: false, error: 'Invalid domain format' };
  }

  if (trimmedInput.length > 255) {
    return { isValid: false, error: 'Domain name too long' };
  }

  const hasValidParts = trimmedInput.split('.').every((part) => part.length <= 63);
  if (!hasValidParts) {
    return { isValid: false, error: 'Domain part exceeds length limit' };
  }

  return { isValid: true, error: '' };
}

/**
 * Helper function to validate individual groups of an IPv6 address
 * Each group must be:
 * 1. 1-4 characters long
 * 2. Contain only valid hexadecimal characters (0-9, A-F, a-f)
 * 3. Represent a value between 0 and FFFF
 */
function validateGroups(groups: string[]): ValidationResult {
  // Verify we have exactly 8 groups
  if (groups.length !== 8) {
    return {
      isValid: false,
      error: `IPv6 address must contain exactly 8 groups, found ${groups.length}`,
    };
  }

  // Validate each individual group
  for (let i = 0; i < groups.length; i += 1) {
    const group = groups[i];

    // Verify group length (1-4 characters)
    if (group.length < 1 || group.length > 4) {
      return {
        isValid: false,
        error: `Group ${i + 1} must be 1-4 characters long: "${group}"`,
      };
    }

    // Verify only valid hexadecimal characters
    if (!/^[0-9A-Fa-f]+$/.test(group)) {
      return {
        isValid: false,
        error: `Group ${i + 1} contains invalid hexadecimal characters: "${group}"`,
      };
    }

    // Verify value is within valid range (0-FFFF)
    const value = parseInt(group, 16);
    if (value < 0 || value > 0xffff) {
      return {
        isValid: false,
        error: `Group ${i + 1} value out of range (0-FFFF): "${group}"`,
      };
    }
  }

  // All validation passed
  return {
    isValid: true,
    error: '',
  };
}

// IPv6 validation
/**
 * Validates if a string is a valid IPv6 address and provides detailed error messages.
 * Supports both full and compressed notation (::).
 *
 * @param input - The string to validate as an IPv6 address
 * @returns ValidationResult object containing validation status and error message
 */
export function validateIPv6(input: string): ValidationResult {
  // Initial preprocessing - Remove leading/trailing whitespace
  const ipString = input.trim();

  // Check if string is empty after trimming
  if (!ipString) {
    return {
      isValid: false,
      error: 'IPv6 address cannot be empty',
    };
  }

  // Handle compressed notation (::)
  if (ipString.includes('::')) {
    // Check for multiple :: compressions (only one allowed)
    const doubleColonCount = (ipString.match(/::/g) || []).length;
    if (doubleColonCount > 1) {
      return {
        isValid: false,
        error: "Only one '::' compression is allowed in IPv6 address",
      };
    }

    // Split address at :: into left and right parts
    const [leftSide, rightSide] = ipString.split('::');

    // Convert each side into groups, or empty array if side is empty
    // Example: "2001:db8::1" -> leftGroups=["2001","db8"], rightGroups=["1"]
    const leftGroups = leftSide ? leftSide.split(':') : [];
    const rightGroups = rightSide ? rightSide.split(':') : [];

    // Calculate missing groups
    // IPv6 must have exactly 8 groups, so :: must represent enough groups
    // to reach 8 when combined with the explicit groups
    const missingGroups = 8 - (leftGroups.length + rightGroups.length);
    if (missingGroups < 0) {
      return {
        isValid: false,
        error: 'Too many groups in IPv6 address',
      };
    }

    // Reconstruct full address by inserting the missing zero groups
    const groups = [...leftGroups, ...Array(missingGroups).fill('0'), ...rightGroups];

    return validateGroups(groups);
  }

  // Handle full notation (no compression)
  const groups = ipString.split(':');
  return validateGroups(groups);
}
