import { AnalysisBadge } from 'components/Events/AnalysisBadge';
import { EmailAnalysis } from 'components/Events/EmailAnalysis';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { useToast } from 'hooks/toast';
import { useOrgId } from 'hooks/useOrgId';
import { Event } from 'models/events';
import { useEffect, useState } from 'react';
import { useGiveFeedbackMutation } from 'service/eventsApi';
import * as Accordion from '@radix-ui/react-accordion';
import { Divider } from 'components/Divider';
import { ReactComponent as IconChevronDown } from 'assets/icons/button-with-circle.svg';
import { ReactComponent as IconAttachment } from 'assets/icons/attachment-icon.svg';
import { ReactComponent as IconLink } from 'assets/icons/link-icon.svg';
import { ReactComponent as IconThumb } from 'assets/icons/thumb.svg';
import { ReactComponent as IconUnsafeAttachment } from 'assets/icons/unsafe-attachment.svg';
import { ReactComponent as IconUnSafeLink } from 'assets/icons/unsafe-link-icon.svg';
import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import {
  getInferenceDetailsByCode,
  getInferences,
  inferenceToProductMap,
} from 'components/Events/data';

interface AnalysisProps {
  event: Event;
  showFeedback?: boolean;
}

export function Analysis({ event, showFeedback = true }: AnalysisProps) {
  const { showToast } = useToast();

  const [isUpvoted, setIsUpvoted] = useState<boolean | null>(null);
  useEffect(() => {
    if (event?.isUpvoted) {
      setIsUpvoted(event.isUpvoted);
    }
  }, [event]);

  const [feedbackForEvent] = useGiveFeedbackMutation();

  // getFeedbackAction returns what action to perform on flagged message
  // if the message is already upvoted and user clicks on 'like' action which means
  // user is trying to revert it, hence we send null action. It's similar with dislike action.
  // in other cases we return action === 'like' (true in case of like and false in case of 'dislike')
  const getFeedbackAction = (action: 'like' | 'dislike') => {
    if (
      (action === 'like' && event.isUpvoted === true) ||
      (action === 'dislike' && event.isUpvoted === false)
    ) {
      return null;
    }

    return action === 'like';
  };

  const [OrgId] = useOrgId();

  const getDefaultOpenAccordion = (): string[] => {
    const accordions: string[] = [];

    if (getInferences('sender', event).length) {
      accordions.push('sender');
    }

    if (getInferences('recipients', event).length) {
      accordions.push('recipients');
    }

    if (event.attachments?.length) {
      event.attachments.forEach((attachment) => {
        if (attachment.suspicious) {
          accordions.push('attachments');
        }
      });
    }

    if (event.links?.length) {
      event.links.forEach((link) => {
        if (link.suspicious) {
          accordions.push('links');
        }
      });
    }

    return accordions;
  };

  const handleFeedback = async (action: 'like' | 'dislike') => {
    const feedbackAction = getFeedbackAction(action);
    setIsUpvoted(feedbackAction);

    try {
      await feedbackForEvent({
        orgId: OrgId,
        messageId: event.messageId,
        feedback: feedbackAction,
      }).unwrap();

      if (feedbackAction === null) {
        showToast({
          component: (
            <SuccessToast
              message={`${action === 'like' ? 'Upvote' : 'Downvote'} removed from flagged message`}
            />
          ),
        });
      } else {
        showToast({
          component: (
            <SuccessToast
              message={`Event successfully ${action === 'like' ? 'upvoted' : 'downvoted'}`}
            />
          ),
        });
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const getRecipientCount = () => {
    return (event?.recipients?.length || 0) + (event?.cc?.length || 0) + (event?.bcc?.length || 0);
  };

  return (
    <div className="w-2/3 flex flex-col h-full overflow-y-auto no-scrollbar">
      <div className="p-8 flex justify-between items-center gap-16 border-b-light border-border-primary">
        <span>{event.subject}</span>
        {showFeedback ? (
          <div className="px-1.5 py-1 bg-soft-sand rounded-full flex gap-1.5">
            <button
              type="button"
              className={`rounded-full p-1.5 ${isUpvoted === true ? 'bg-success-alert' : 'bg-white'}`}
              onClick={() => handleFeedback('like')}
              aria-label="like"
            >
              <IconThumb
                className={`w-2.5 h-2.5  ${isUpvoted === true ? 'fill-white' : 'fill-success-alert'}`}
              />
            </button>
            <button
              type="button"
              className={`rounded-full p-1.5 ${isUpvoted === false ? 'bg-critical-red' : 'bg-white'}`}
              onClick={() => handleFeedback('dislike')}
              aria-label="dislike"
            >
              <IconThumb
                className={`w-2.5 h-2.5 rotate-180 ${isUpvoted === false ? 'fill-white' : 'fill-critical-red'}`}
              />
            </button>
          </div>
        ) : null}
      </div>
      <div className="flex-1">
        <div className="px-8 py-6">
          <Accordion.Root type="multiple" defaultValue={getDefaultOpenAccordion()}>
            <EmailAnalysis event={event} />
            <Divider customCss="my-6 border-border-light border-border-primary" />
            <Accordion.Item value="sender">
              <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                <span className="text-xs text-light-grey">Sender</span>
                <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
              </Accordion.Trigger>
              <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex justify-between items-center mt-4 gap-4">
                    <div className="flex items-center gap-4">
                      <Avatar
                        initials={getInitials(event.sender.email, 2)}
                        bgColor="avatar-purple"
                        textColor="white"
                        customCssClass="w-6 h-6 p-1 text-xs"
                      />
                      <span className="text-sm text-black">{event.sender.email}</span>
                      {event.sender.regulator ? (
                        <div className="p-1 bg-soft-purple rounded-md text-dark-purple text-xs">
                          Regulator
                        </div>
                      ) : null}
                      {event.sender.vendor ? (
                        <div className="p-1 bg-soft-purple rounded-md text-dark-purple text-xs">
                          Vendor
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {getInferences('sender', event)?.length > 0 && (
                    <div className="flex flex-wrap gap-1">
                      {getInferences('sender', event)?.map((inference) => {
                        const inferenceDetails = getInferenceDetailsByCode(inference);
                        return (
                          <AnalysisBadge
                            key={inference}
                            text={inferenceDetails.text}
                            type={inferenceToProductMap[inference] || 'others'}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Divider customCss="my-6 border-border-light border-border-primary" />
            <Accordion.Item value="recipients">
              <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                <span className="text-xs text-light-grey">
                  Recipients{' '}
                  {`(${getRecipientCount() ? getRecipientCount() : event.webhookRecipients?.length || '0'})`}
                </span>
                <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
              </Accordion.Trigger>
              <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                <div className="flex flex-col gap-4 mt-4">
                  {event?.recipients?.map((recipient) => (
                    <div className="flex justify-between items-center mt-4 gap-4">
                      <div className="flex items-center gap-4">
                        <Avatar
                          initials={getInitials(recipient.email, 2)}
                          bgColor="avatar-blue"
                          textColor="white"
                          customCssClass="w-6 h-6 p-1 text-xs"
                        />
                        <span className="text-sm text-black">{recipient.email}</span>
                        {recipient.vip ? (
                          <div className="p-1 bg-soft-blue rounded-md text-avatar-blue text-xs">
                            VIP
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  {event?.cc?.map((recipient) => (
                    <div className="flex justify-between items-center mt-4 gap-4">
                      <div className="flex items-center gap-4">
                        <Avatar
                          initials={getInitials(recipient.email, 2)}
                          bgColor="avatar-blue"
                          textColor="white"
                          customCssClass="w-6 h-6 p-1 text-xs"
                        />
                        <span className="text-sm text-black">{recipient.email}</span>
                        <div className="p-1 bg-select-background rounded-md text-light-grey text-xs">
                          CC
                        </div>
                      </div>
                    </div>
                  ))}
                  {event?.bcc?.map((recipient) => (
                    <div className="flex justify-between items-center mt-4 gap-4">
                      <div className="flex items-center gap-4">
                        <Avatar
                          initials={getInitials(recipient.email, 2)}
                          bgColor="avatar-blue"
                          textColor="white"
                          customCssClass="w-6 h-6 p-1 text-xs"
                        />
                        <span className="text-sm text-black">{recipient.email}</span>
                        <div className="p-1 bg-select-background rounded-md text-light-grey text-xs">
                          BCC
                        </div>
                      </div>
                    </div>
                  ))}
                  {getRecipientCount() === 0 &&
                    event.webhookRecipients.map((recipient) => (
                      <div className="flex justify-between items-center mt-4 gap-4">
                        <div className="flex items-center gap-4">
                          <Avatar
                            initials={getInitials(recipient.email, 2)}
                            bgColor="avatar-blue"
                            textColor="white"
                            customCssClass="w-6 h-6 p-1 text-xs"
                          />
                          <span className="text-sm text-black">{recipient.email}</span>
                        </div>
                      </div>
                    ))}
                  {getInferences('recipients', event)?.length > 0 && (
                    <div className="flex flex-wrap gap-1">
                      {getInferences('recipients', event)?.map((inference) => {
                        const inferenceDetails = getInferenceDetailsByCode(inference);
                        return (
                          <AnalysisBadge
                            key={inference}
                            text={inferenceDetails.text}
                            type={inferenceToProductMap[inference] || 'others'}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Divider customCss="my-6 border-border-light border-border-primary" />
            <Accordion.Item value="attachments">
              <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                <span className="text-xs text-light-grey">
                  Attachments {`(${event.attachments?.length || '0'})`}
                </span>
                <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
              </Accordion.Trigger>
              <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                <div className="flex flex-col gap-4 mt-4">
                  {event.attachments?.map((attachment) => (
                    <div className="flex justify-between items-center mt-4 gap-4">
                      <div className="flex items-center gap-4">
                        {attachment.suspicious ? (
                          <IconUnsafeAttachment className="w-6 h-6" />
                        ) : (
                          <IconAttachment className="w-6 h-6" />
                        )}
                        <span
                          className={`text-sm ${attachment.suspicious ? 'text-red-600' : 'text-black'}`}
                        >
                          {attachment.name}
                        </span>
                      </div>
                    </div>
                  ))}
                  {getInferences('attachments', event)?.length > 0 && (
                    <div className="flex flex-wrap gap-1">
                      {getInferences('attachments', event)?.map((inference) => {
                        const inferenceDetails = getInferenceDetailsByCode(inference);
                        return (
                          <AnalysisBadge
                            key={inference}
                            text={inferenceDetails.text}
                            type={inferenceToProductMap[inference] || 'others'}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Divider customCss="my-6 border-border-light border-border-primary" />
            <Accordion.Item value="links">
              <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                <span className="text-xs text-light-grey">
                  Links {`(${event.links?.length || '0'})`}
                </span>
                <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
              </Accordion.Trigger>
              <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                <div className="flex flex-col gap-4 mt-4">
                  {event.links?.map((link) => (
                    <div className="flex justify-between items-center mt-4 gap-4">
                      <div className="flex items-center gap-4">
                        {link.suspicious ? (
                          <IconUnSafeLink className="w-6 h-6" />
                        ) : (
                          <IconLink className="w-6 h-6" />
                        )}
                        <span
                          className={`text-sm ${link.suspicious ? 'text-red-600' : 'text-black'}`}
                        >{`${link.root}${link.path}`}</span>
                      </div>
                    </div>
                  ))}
                  {getInferences('links', event)?.length > 0 && (
                    <div className="flex flex-wrap gap-1">
                      {getInferences('links', event)?.map((inference) => {
                        const inferenceDetails = getInferenceDetailsByCode(inference);
                        return (
                          <AnalysisBadge
                            key={inference}
                            text={inferenceDetails.text}
                            type={inferenceToProductMap[inference] || 'others'}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </div>
    </div>
  );
}
