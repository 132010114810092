import { useState } from 'react';

import { RemediatedEventsFilter } from 'models/events';
import DateFilter from 'components/DateFilter';
import { Navbar } from 'components/Layouts/Navbar';
import Statistics from 'components/Statistics';
import { dateUtils } from 'utils/time';
import { Divider } from 'components/Divider';
import { RemediatedEvents } from 'components/Remediation/RemediatedEvents';

export default function Remediation() {
  const [tableFilters, setTableFilters] = useState<RemediatedEventsFilter>({
    limit: 10,
    offset: 0,
    // setting up default date filters
    from: dateUtils.subtract(new Date().toDateString(), 6, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
    sortBy: 'time',
    sortOrder: 'desc',
    threat: '',
    isAutomated: false,
  });

  const handleDateFilters = (from: string, to: string) => {
    setTableFilters({
      ...tableFilters,
      from,
      to,
    });
  };
  return (
    <div className="w-full px-8 py-5">
      <Navbar title="Remediation List" />
      <div className="flex items-center justify-between">
        <Statistics from={tableFilters.from || ''} to={tableFilters.to || ''} />
        <DateFilter onClick={handleDateFilters} />
      </div>
      <Divider />
      <RemediatedEvents filter={tableFilters} setFilter={setTableFilters} />
    </div>
  );
}
