import { useState } from 'react';
import { GetEventsQueryParams } from 'models/events';
import { Navbar } from 'components/Layouts/Navbar';
import Statistics from 'components/Statistics';
import DateFilter from 'components/DateFilter';
import { Divider } from 'components/Divider';
import { useGetFlaggedEventsQuery } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import AllEvents from 'components/Events';
import { dateUtils } from 'utils/time';
import { Outlet } from 'react-router-dom';

export default function Home() {
  const [tableFilters, setTableFilters] = useState<GetEventsQueryParams>({
    limit: 10,
    offset: 0,
    // setting up default date filters
    from: dateUtils.subtract(new Date().toDateString(), 6, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
    sortBy: 'time',
    sortOrder: 'desc',
    vector: '',
    threat: '',
    status: '',
  });

  const handleDateFilters = (from: string, to: string) => {
    setTableFilters({
      ...tableFilters,
      from,
      to,
    });
  };

  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetFlaggedEventsQuery({
    orgId: OrgId,
    ...tableFilters,
  });

  return (
    <div className="w-full px-8 py-5">
      <Navbar title="Threats" />
      <div className="flex items-center justify-between">
        <Statistics from={tableFilters.from || ''} to={tableFilters.to || ''} />
        <DateFilter onClick={handleDateFilters} />
      </div>
      <Divider />
      <AllEvents
        data={data}
        isLoading={isLoading}
        filters={tableFilters}
        setFilters={setTableFilters}
      />
      <Outlet />
    </div>
  );
}
