import { Badge } from 'components/Badge';
import { BlacklistItem } from 'models/events';
import { dateUtils } from 'utils/time';
import { ReactComponent as IconEye } from 'assets/icons/eye.svg';
import { getEmailDetails } from 'components/Events/data';
import { useNavigate } from 'react-router-dom';
import { toTitleCase } from 'utils/name';

import { remediationActionDetails } from './data';

interface BlacklistedRowProps {
  event: BlacklistItem;
}

export function BlacklistedRow({ event }: BlacklistedRowProps) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/remediation/blocklist/${event.messageId}`);
  };

  const formatAppliedRuleText = () => {
    return `🚫 ${toTitleCase(event.type)} Blocked`;
  };

  return (
    <tr
      className="border-b align-top cursor-pointer"
      data-message-id={event.id}
      onClick={() => handleNavigate()}
    >
      <td title={event.subject} className="text-start py-6 px-3">
        <p className="text-black text-sm max-w-full text-ellipsis line-clamp-2">{event.subject}</p>
      </td>
      <td className="text-start py-6 px-3 text-black text-sm">
        <p className="max-w-full break-words text-ellipsis line-clamp-1">
          <span className="text-light-grey">From:</span> {getEmailDetails(event.sender)}
        </p>
        <p className="max-w-full break-words text-ellipsis line-clamp-2">
          <span className="text-light-grey"> To: </span>{' '}
          {event.recipients?.length > 1
            ? `${event.recipients?.length} Recipients`
            : getEmailDetails(event?.recipients?.[0])}
        </p>
      </td>
      <td className="text-start px-3 py-6 text-sm">
        <Badge
          borderColor="border-primary"
          text="Auto Remediated"
          borderRadius="rounded-md"
          bgColor="soft-green"
          textCase="capitalize"
          textSize="text-sm"
        />
      </td>
      <td className="text-start px-3 py-6 text-sm max-w-full break-words">
        <p className="px-2 py-1 border-light border-border-primary rounded-lg bg-soft-blue w-fit">
          {formatAppliedRuleText()}{' '}
          <span className="bg-white rounded-md px-1.5 py-0.5">{event.value}</span>
        </p>
      </td>
      <td className="text-start px-2 py-6 text-black text-sm">
        <div>{dateUtils.format(event?.emailDate, 'DD/MM/YYYY')?.toString()}</div>
        <div className="text-light-grey">
          {dateUtils?.format(event?.emailDate, 'hh:mm A')?.toString()}
        </div>
      </td>
      <td className="text-start px-2 py-6 text-black text-sm">
        <div>{dateUtils.format(event?.remediationDate, 'DD/MM/YYYY')?.toString()}</div>
        <div className="text-light-grey">
          {dateUtils?.format(event?.remediationDate, 'hh:mm A')?.toString()}
        </div>
      </td>
      <td className="text-start px-4 py-6 text-black text-sm flex flex-col gap-2">
        {remediationActionDetails[event.action]?.text.map((rAction) => (
          <button
            key={rAction}
            type="button"
            className="border-light border-border-primary text-black rounded-lg bg-soft-gray py-1 px-2 w-fit"
          >
            {rAction}
          </button>
        ))}
      </td>
      <td className="px-3 py-6">
        <div className="border-light border-border-primary rounded-md w-8 h-8 flex justify-center items-center">
          <IconEye className="fill-light-grey" />
        </div>
      </td>
    </tr>
  );
}
