import { useOrgId } from 'hooks/useOrgId';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRemediatedEventQuery } from 'service/eventsApi';
import { RemediateOverview } from '../../components/Remediation/RemediateOverview';

export function RemediationDetails() {
  const { messageId } = useParams();
  const navigate = useNavigate();
  const [OrgId] = useOrgId();

  // Prevent body scrolling when overlay is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const { data, isLoading } = useGetRemediatedEventQuery({
    messageId: messageId || '',
    organizationId: OrgId,
  });

  const handleClose = () => {
    navigate('/remediation');
  };

  return (
    <RemediateOverview
      open
      onClose={handleClose}
      event={data?.event}
      isLoading={isLoading}
      action={data?.action || ''}
      remediateDate={data?.createdAt || ''}
    />
  );
}
