import { createApi } from '@reduxjs/toolkit/query/react';
import { BLACKLIST_URL, DELETE_BLACKLIST_URL, VALIDATE_LIST_URL } from 'constants/apiUrls';
import { ListResults, ListFilter, ListReq, RuleFilter, ValidationDetails } from 'models/rules';
import { baseQuery } from './baseQuery';

export const blacklistApi = createApi({
  reducerPath: 'blacklistApi',
  baseQuery: baseQuery,
  tagTypes: ['BLACKLIST'],
  endpoints: (builder) => ({
    getBlacklist: builder.query<ListResults, ListFilter>({
      query: ({ orgId, type, limit, offset }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());

        if (type) params.append('type', type);

        return `${BLACKLIST_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['BLACKLIST'],
    }),
    createBlacklist: builder.mutation<undefined, ListReq & { orgId: string }>({
      query: ({ orgId, listEntries }) => {
        return {
          url: `${BLACKLIST_URL(orgId)}`,
          body: {
            listEntries,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['BLACKLIST'],
    }),
    deleteBlacklist: builder.mutation<
      undefined,
      { orgId: string; listId: string; type: RuleFilter }
    >({
      query: ({ orgId, listId, type }) => ({
        url: `${DELETE_BLACKLIST_URL(orgId, listId)}?type=${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BLACKLIST'],
    }),
    validateBlacklist: builder.mutation<
      Record<string, ValidationDetails>,
      ListReq & { orgId: string }
    >({
      query: ({ listEntries, orgId }) => {
        return {
          method: 'POST',
          url: VALIDATE_LIST_URL(orgId, 'blacklist'),
          body: {
            listEntries,
          },
        };
      },
    }),
  }),
});

export const {
  useGetBlacklistQuery,
  useCreateBlacklistMutation,
  useDeleteBlacklistMutation,
  useValidateBlacklistMutation,
} = blacklistApi;
