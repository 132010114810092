import { Rule } from 'models/rules';

import Avatar from 'components/Avatar';

import { dateUtils } from 'utils/time';
import { getInitials } from 'utils/name';

import { DeleteAlert } from './DeleteAlert';

interface RuleRow extends Rule {
  onDelete: () => void;
}
export function RuleRow({ entity, createdAt, addedBy, comment, onDelete }: RuleRow) {
  const handleConfirm = async () => {
    onDelete();
  };

  return (
    <tr className="border-b-light align-middle border-border-primary">
      <td className="py-6 px-3 text-sm text-black">{entity}</td>
      <td className="text-start py-6 px-3 text-black text-sm">
        <div>{dateUtils.format(createdAt, 'DD/MM/YYYY')?.toString()}</div>
        <div className="text-light-grey">{dateUtils?.format(createdAt, 'hh:mm A')?.toString()}</div>
      </td>
      <td className="text-start py-6 px-3 text-black text-sm">
        <div className="flex items-center gap-2">
          <Avatar
            customCssClass="w-6 h-6 text-xs"
            bgColor="soft-blue"
            textColor="avatar-blue"
            initials={getInitials(
              (addedBy?.name !== '' ? addedBy?.name : addedBy?.email) || 'NA',
              2
            )}
          />
          <span>{(addedBy?.name !== '' ? addedBy?.name : addedBy?.email) || 'NA'}</span>
        </div>
      </td>
      <td className="text-start py-6 px-3 text-black text-sm">{comment}</td>
      <td className="py-6 px-3 text-center">
        <DeleteAlert onConfirm={handleConfirm} />
      </td>
    </tr>
  );
}
