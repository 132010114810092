import { createBrowserRouter } from 'react-router-dom';

import RootLayout from 'components/Layouts';

import Home from 'pages/Home';
import Users from 'pages/Users';
import Settings from 'pages/Settings';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Onboarding from 'pages/Onboarding';
import Remediation from 'pages/Remediation';
import Analytics from 'pages/Analytics';
import Policies from 'pages/Policies';
import Rules from 'pages/Rules';
import { EventDetails } from 'pages/Home/EventDetails';
import { RemediationDetails } from 'pages/Remediation/RemediationDetails';
import { BlacklistEventDetails } from 'pages/Remediation/BlacklistDetails';

import ProtectedRoute from './ProtectedRoutes';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/onboarding',
    element: (
      <ProtectedRoute>
        <Onboarding />
      </ProtectedRoute>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
        children: [
          {
            path: '/events/:messageId',
            element: <EventDetails />,
          },
        ],
      },
      {
        element: <Users />,
        path: '/users',
      },
      {
        element: <Remediation />,
        path: '/remediation',
        children: [
          {
            path: '/remediation/:messageId',
            element: <RemediationDetails />,
          },
          {
            path: '/remediation/blocklist/:id',
            element: <BlacklistEventDetails />,
          },
        ],
      },
      {
        element: <Settings />,
        path: '/settings',
      },
      {
        element: <Analytics />,
        path: '/analytics',
      },
      {
        element: <Policies />,
        path: '/policies',
      },
      {
        element: <Rules />,
        path: '/rules',
      },
    ],
  },
]);

export default router;
