import { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Select from '@radix-ui/react-select';

import { Event } from 'models/events';

import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info-icon.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { useRemediateMessageMutation } from 'service/eventsApi';
import { useForceLogoutMutation } from 'service/users';
import { useOrgId } from 'hooks/useOrgId';

import Loader from 'components/Loader';
import { useToast } from 'hooks/toast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { WarningToast } from 'components/Toasts/WarningToast';
import { MOVE_TO_JUNK, QUARANTINE_MESSAGE } from 'constants/remediationActions';
import { Divider } from 'components/Divider';

import { RemediationActions } from 'enums/remediationAction';
import { Analysis } from 'components/EmailAttributes/Analysis';

interface EventOverviewProps {
  event: Event | undefined;
  open: boolean;
  onClose: () => void;
  generateActionDescription: (
    checkedStatus: Record<'logout' | 'quarantine', boolean>,
    actionResult: Record<'logout' | 'quarantine', boolean>
  ) => string;
  isLoading: boolean;
}

export function EventOverview({
  open,
  onClose,
  event,
  generateActionDescription,
  isLoading,
}: EventOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    logout: false,
    quarantine: false,
  });

  const [loading, setLoading] = useState(false);

  const [remediateMsg] = useRemediateMessageMutation();

  const [forceLogoutUser] = useForceLogoutMutation();

  const [OrgId] = useOrgId();

  const [selectedRemediationAction, setSelectedRemediationAction] =
    useState<RemediationActions>('junk');

  if (!event || isLoading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-2/3 h-overview-modal  bg-white rounded-lg">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  const handleToggle = (action: 'logout' | 'quarantine') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const handleRemediateCheck = () => {
    handleToggle('quarantine');
  };

  const handleSelect = (value: RemediationActions) => {
    setSelectedRemediationAction(value);
  };

  // handleRemediate completes 2 actions:
  // 1. Quarantine the mail
  // 2. Logout the user
  // depending on whether the user has checked the checkboxes or not
  const handleRemediate = async () => {
    if (!(checkedStateOfActions.logout || checkedStateOfActions.quarantine)) {
      showToast({
        component: <WarningToast message="Please select at least one action" />,
      });
      return;
    }

    setLoading(true);

    const actionResult = {
      logout: false,
      quarantine: false,
    };

    try {
      if (checkedStateOfActions.logout) {
        await forceLogoutUser({
          orgId: OrgId,
          employeeId: event.employeeId,
          messageId: event.messageId,
        }).unwrap();

        actionResult.logout = true;
      }

      if (checkedStateOfActions.quarantine) {
        await remediateMsg({
          orgId: OrgId,
          messageId: event.messageId,
          folder: selectedRemediationAction === 'junk' ? MOVE_TO_JUNK : QUARANTINE_MESSAGE,
        }).unwrap();

        actionResult.quarantine = true;
      }

      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full max-h-overview-modal bg-white rounded-lg flex justify-center">
            <Analysis event={event} />
            <div className="w-1/3 border-l-light border-border-primary flex flex-col justify-between gap-8 relative">
              <button
                type="submit"
                className="absolute w-fit top-4 right-4 p-1 rounded-full border-light border-border-primary flex justify-center items-center hover:bg-black cursor-pointer"
                onClick={() => onClose()}
                aria-label="Close"
              >
                <IconCross className="w-2 h-2 hover:stroke-white" />
              </button>
              <div className="p-8">
                <div className="flex flex-col">
                  <span className="text-lg text-light-grey">Suggested Remediation Actions</span>
                  <Divider customCss="border-border-light border-border-primary mt-6 mb-4" />
                  <div className="flex flex-col gap-2">
                    <div className="w-full flex gap-4 items-center">
                      <Checkbox.Root
                        className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                        checked={checkedStateOfActions.logout}
                        onClick={() => handleToggle('logout')}
                      >
                        <Checkbox.Indicator>
                          <IconCheck />
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <button
                        className="flex flex-col gap-1"
                        onClick={() => handleToggle('logout')}
                        type="button"
                      >
                        <span className="text-xs">Log Out Recepients & Reset Password</span>
                      </button>
                    </div>
                    <span className="text-2xs text-light-grey">
                      This action cannot be reversed. Recipient will continue to be signed out of
                      all active sessions.
                    </span>
                  </div>
                  <Divider customCss="border-border-light border-border-primary my-4" />
                  {event.status === 'Pending' ? (
                    <>
                      <div className="w-full flex gap-4 justify-between relative">
                        <div className="flex items-center gap-4">
                          <Checkbox.Root
                            className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                            checked={checkedStateOfActions.quarantine}
                            onClick={() => handleRemediateCheck()}
                          >
                            <Checkbox.Indicator>
                              <IconCheck />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <button
                            className="flex flex-col gap-1"
                            onClick={() => handleRemediateCheck()}
                            type="button"
                          >
                            <span className="text-xs">Push Mail to</span>
                          </button>
                        </div>
                        <Select.Root defaultValue="junk" onValueChange={handleSelect}>
                          <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                            <span className="text-black">
                              <Select.Value />
                            </span>
                            <Select.Icon>
                              <IconArrowDown className="w-4 h-4" />
                            </Select.Icon>
                          </Select.Trigger>
                          <Select.Portal>
                            <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                              <Select.Viewport>
                                <Select.Item
                                  value="quarantine"
                                  className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                                >
                                  <Select.ItemText>Quarantine</Select.ItemText>
                                </Select.Item>
                                <Select.Item
                                  value="junk"
                                  className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                                >
                                  <Select.ItemText>Junk</Select.ItemText>
                                </Select.Item>
                              </Select.Viewport>
                            </Select.Content>
                          </Select.Portal>
                        </Select.Root>
                      </div>
                      <Divider customCss="border-border-light border-border-primary my-4" />
                    </>
                  ) : null}
                </div>
              </div>
              <div className="px-8 py-6 border-t-light border-border-primary flex flex-col gap-8 items-center">
                <div className="flex flex-row gap-4 items-center">
                  <IconInfo className="w-6 h-6" />
                  <span className="text-xs text-light-grey">
                    Any email that will is moved to quarantine will stay there up to{' '}
                    <strong className="font-black"> 7 days </strong> as per your Retention policy
                  </span>
                </div>
                <button
                  className="w-full bg-success-alert rounded-full text-white p-4 text-center text-base"
                  type="button"
                  onClick={() => handleRemediate()}
                >
                  {loading ? <Loader color="white" /> : 'Trigger Actions'}
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
