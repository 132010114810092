interface RuleTableHeaderProps {
  columns: {
    name: string;
    width: string;
  }[];
}

export function RuleTableHeader({ columns }: RuleTableHeaderProps) {
  return (
    <>
      <colgroup>
        {columns.map((header) => (
          <col key={header.name} className={header.width} />
        ))}
      </colgroup>
      <thead className="text-sm text-light-grey w-full border-y-light border-border-primary">
        <tr>
          {columns.map((column) => (
            <th
              key={column.name}
              scope="col"
              className="py-6 px-3 font-normal text-xs text-start text-light-grey"
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
}
