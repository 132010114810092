import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_CONFIG, UPDATE_CONFIG } from 'constants/apiUrls';
import { FeatureConfig, UpdateModeReq } from 'models/config';
import { baseQuery } from './baseQuery';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQuery,
  tagTypes: ['CONFIG'],
  endpoints: (builder) => ({
    getConfig: builder.query<FeatureConfig, { orgId: string }>({
      query: ({ orgId }) => {
        return GET_CONFIG(orgId);
      },
      providesTags: ['CONFIG'],
    }),
    updateConfig: builder.mutation<undefined, UpdateModeReq & { orgId: string }>({
      query: ({ orgId, mode }) => {
        return {
          url: UPDATE_CONFIG(orgId),
          body: {
            mode,
          },
          method: 'PUT',
        };
      },
      invalidatesTags: ['CONFIG'],
    }),
  }),
});

export const { useGetConfigQuery, useUpdateConfigMutation } = configApi;
