import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconSubtract } from 'assets/icons/icon-subtract.svg';
import { ListRecord, ValidationDetails } from 'models/rules';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

interface RoleFormInput {
  onAdd: (index: number) => void;
  onClose: (index: number) => void;
  index: number;
  value: ListRecord & { error?: string };
  onChange: (index: number, key: string, value: string) => void;
  validation?: ValidationDetails;
}

export function RuleFormInput({
  onAdd,
  onClose,
  value,
  index,
  onChange,
  validation,
}: RoleFormInput) {
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="w-full flex items-center gap-4">
        <input
          className="px-4 py-2.5 flex-1 bg-soft-gray placeholder:text-light-grey border-light border-border-primary rounded-md outline-none"
          placeholder="Add an email address, domain or IP"
          value={value.value}
          onChange={(e) => onChange(index, 'value', e.target.value)}
        />
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="w-8 h-8 text-xl flex items-center justify-center p-1.5 rounded-md border-light border-border-primary text-light-grey"
            onClick={() => onClose(index)}
          >
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <IconSubtract />
          </button>
          <button
            type="button"
            className="w-8 h-8 text-xl flex items-center justify-center p-1.5 rounded-md border-light border-border-primary text-light-grey"
            onClick={() => onAdd(index)}
          >
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <IconAdd />
          </button>
        </div>
      </div>
      <input
        placeholder="Add a comment"
        className="placeholder:text-light-grey px-4 py-2.5 bg-soft-gray rounded-md border-light border-border-primary outline-none"
        value={value.comment}
        onChange={(e) => onChange(index, 'comment', e.target.value)}
      />
      {value?.error ? (
        <div className="w-full flex items-center gap-2">
          <InfoIcon className="w-3.5 h-3.5 fill-critical-red" />
          <span className="text-critical-red text-xs">{value.error}</span>
        </div>
      ) : null}
      {validation && validation.error ? (
        <div className="w-full flex items-center gap-2">
          <InfoIcon
            className={`w-3.5 h-3.5 ${validation.valid ? 'fill-soft-brown' : 'fill-critical-red'} `}
          />
          <span className={`${validation.valid ? 'text-soft-brown' : 'text-critical-red'} text-xs`}>
            {validation.error}
          </span>
        </div>
      ) : null}
    </div>
  );
}
