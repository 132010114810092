import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Badge } from 'components/Badge';
import { getRecipientDisplayName } from 'components/Events/data';
import { Alert } from 'components/Alert';

import { ReactComponent as IconUndoRemediation } from 'assets/icons/undo-remediation.svg';
import { ReactComponent as IconMenu } from 'assets/icons/icon-action-menu.svg';

import { useUndoRemediationMutation } from 'service/eventsApi';

import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { useAuth } from 'contexts/AuthContext';

import { RemediationItem } from 'models/events';
import { dateUtils } from 'utils/time';
import { UNDO_MOVE_TO_JUNK, UNDO_QUARANTINE_MESSAGE } from 'constants/remediationActions';

import { UndoRemediationAlert } from './UndoRemediation';
import { remediationActionDetails } from './data';

export function RemediationRow({
  action,
  actionBy,
  createdAt,
  event,
  isAutomated,
  reason,
  userRemediation,
}: RemediationItem) {
  const { showToast } = useToast();

  const navigate = useNavigate();

  const { email } = useAuth();

  const [loading, setLoading] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAlertOpen(true);
  };

  const [undoRemediation] = useUndoRemediationMutation();

  const [OrgId] = useOrgId();

  const handleConfirm = async () => {
    // avoid multiple api calls
    if (loading) return;

    setLoading(true);

    try {
      await undoRemediation({
        orgId: OrgId,
        messageId: event.messageId,
        action: action === 'MOVE_TO_JUNK' ? UNDO_MOVE_TO_JUNK : UNDO_QUARANTINE_MESSAGE,
      }).unwrap();

      showToast({
        component: (
          <ActionToast
            description="Moved message to 'Inbox'"
            showUndo={false}
            initials={getInitials(email, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ErrorToast message='Failed to move message to "Inbox", please try again later' />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <tr
        className="border-b align-top cursor-pointer"
        onClick={() => navigate(`/remediation/${event.messageId}`)}
        data-message-id={event.messageId}
      >
        <td title={event.subject} className="text-start py-6 px-3">
          <p className="text-black text-sm max-w-full text-ellipsis line-clamp-2">
            {event.subject}
          </p>
        </td>
        <td className="text-start py-6 px-3 text-black text-sm">
          <p className="max-w-full break-words text-ellipsis line-clamp-1">
            <span className="text-light-grey">From:</span> {event.sender.name}
          </p>
          <p className="max-w-full break-words text-ellipsis line-clamp-2">
            <span className="text-light-grey"> To: </span> {getRecipientDisplayName(event)}
          </p>
        </td>
        <td className="text-start py-6 px-3 text-black text-sm flex flex-col flex-wrap gap-2">
          <div className="w-full">
            <Badge
              borderColor="border-primary"
              text={isAutomated ? 'Auto Remediated' : 'Manual'}
              borderRadius="rounded-md"
              bgColor={isAutomated ? 'soft-green' : 'soft-grey'}
              textCase="capitalize"
              textSize="text-sm"
            />
          </div>
          <p
            title={actionBy}
            className="text-black text-sm max-w-full w-fit text-ellipsis line-clamp-1"
          >
            {actionBy}
          </p>
        </td>
        <td className="text-start px-3 py-6 text-sm">{reason}</td>
        <td className="text-start px-2 py-6 text-black text-sm">
          <div>{dateUtils.format(event?.time, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(event.time, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-sm">
          <div>{dateUtils.format(createdAt, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(createdAt, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-sm flex flex-col gap-2">
          {remediationActionDetails[action]?.text.map((eachAction) => (
            <button
              key={eachAction}
              type="button"
              className="border-light border-border-primary text-black rounded-lg bg-soft-gray py-1 px-2 w-fit"
            >
              {eachAction}
            </button>
          ))}
          {userRemediation
            ? remediationActionDetails[userRemediation.action]?.text.map((rAction) => (
                <button
                  key={rAction}
                  type="button"
                  className="border-light border-border-primary text-black rounded-lg bg-soft-gray py-1 px-2 w-fit"
                >
                  {rAction}
                </button>
              ))
            : null}
        </td>
        <td className="px-3 py-6">
          <IconMenu className="cursor-pointer" onClick={handleAlertOpen} />
        </td>
      </tr>
      <Alert
        open={alertOpen}
        cancelText="Cancel"
        confirmText="Confirm"
        content={<UndoRemediationAlert />}
        onConfirm={() => handleConfirm()}
        onCancel={() => setAlertOpen(false)}
        message="Are you sure you want to undo the remediation actions taken for this email? The following actions will be performed"
        icon={<IconUndoRemediation />}
        loading={loading}
      />
    </>
  );
}
