import { useOrgId } from 'hooks/useOrgId';
import { RuleFilter } from 'models/rules';
import { useState } from 'react';
import { useToast } from 'hooks/toast';

import { useDeleteAllowedListMutation, useGetAllowedListQuery } from 'service/whitelist';
import Loader from 'components/Loader';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { EmptyRule } from './EmptyRule';
import { RuleRow } from './RuleRow';
import { RuleTableHeader } from './RuleTableHeader';

interface RuleListProps {
  ruleType: RuleFilter | '';
  onAddRule: () => void;
}
export function AllowRuleList({ ruleType, onAddRule }: RuleListProps) {
  const { showToast } = useToast();

  const columns = [
    {
      name: 'Email Address / Domain / IP',
      width: 'w-3/10',
    },
    {
      name: 'Date Added',
      width: 'w-1/5',
    },
    {
      name: 'Added By',
      width: 'w-1/5',
    },
    {
      name: 'Comments',
      width: 'w-1/5',
    },
    {
      name: 'Actions',
      width: 'w-1/10',
    },
  ];

  const [orgId] = useOrgId();

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  const { data, isLoading } = useGetAllowedListQuery({
    orgId,
    type: ruleType,
    limit: pagination.limit,
    offset: pagination.offset,
  });

  const getFallback = () => {
    if (!data?.hits?.length) {
      return (
        <td colSpan={columns.length}>
          <EmptyRule onAddRule={() => onAddRule()} />
        </td>
      );
    }

    if (isLoading) {
      return (
        <td colSpan={columns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }

    return null;
  };

  const [deleteBlacklist] = useDeleteAllowedListMutation();

  const onDelete = async (rule: RuleFilter, entityId: string) => {
    try {
      await deleteBlacklist({
        orgId,
        listId: entityId,
        type: rule,
      }).unwrap();

      showToast({
        component: <SuccessToast message="Successfully deleted the rule" />,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Unable to delete rule. Please try again." />,
      });
    }
  };

  return (
    <>
      <table className="w-full mt-8">
        <RuleTableHeader columns={columns} />
        <tbody className="w-full">
          {isLoading || !data?.hits?.length
            ? getFallback()
            : data?.hits?.map((rule) => (
                <RuleRow
                  key={rule.entityId}
                  id={rule.id}
                  entity={rule.entity}
                  createdAt={rule.createdAt}
                  addedBy={rule.addedBy}
                  comment={rule.comment}
                  listType={rule.listType}
                  entityId={rule.entityId}
                  onDelete={() => onDelete(rule.listType, rule.entityId)}
                />
              ))}
        </tbody>
      </table>
      {data?.hits?.length && !isLoading && (
        <div className="flex justify-between px-6 py-4 items-center">
          <span className="text-xs font-light">
            Showing {data && data?.hits?.length > 0 ? pagination.offset + 1 : '0'} -{' '}
            {data && data?.hits?.length > 0 ? pagination.offset + (data?.hits?.length || 0) : '0'}{' '}
            of {data?.total} events
          </span>
          <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
            <IconArrowDown
              className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${data && pagination?.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (data) {
                  if (data.offset > 0) {
                    setPagination({
                      ...pagination,
                      offset: data.offset - data.limit,
                    });
                  }
                }
              }}
            />
            <IconArrowDown
              className={`w-6 h-6 -rotate-90 ${data && pagination.offset + pagination.limit < data.total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (data && pagination.offset + pagination.limit < data?.total) {
                  setPagination({
                    ...pagination,
                    offset: pagination.offset + pagination.limit,
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
