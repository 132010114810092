import { useState } from 'react';

import Loader from 'components/Loader';
import { NoThreatFallback } from 'components/Threats/NoThreatFallback';
import { BlacklistedEventResults } from 'models/events';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import Sort from 'components/Sort';

import { BlacklistedRow } from './BlacklistedRow';

interface BlacklistedTableProps {
  data?: BlacklistedEventResults;
  isLoading: boolean;
  total: number;
  limit: number;
  offset: number;
  handlePagination: (limit: number, offset: number) => void;
  onSort: (sortBy: string, sortOrder: string) => void;
}

export function BlacklistedTable({
  data,
  isLoading,
  total,
  limit,
  offset,
  handlePagination,
  onSort,
}: BlacklistedTableProps) {
  const headerColumns = [
    {
      name: 'Mail Subject',
      width: 'w-1/8',
    },
    {
      name: 'Sender & Recipients',
      width: 'w-1/6',
    },
    {
      name: 'Remediated By',
      width: 'w-1/8',
    },
    {
      name: 'Rule Applied',
      width: 'w-1/6',
    },
    {
      name: 'Email Date',
      width: 'w-1/12',
    },
    {
      name: 'Rem. Date',
      width: 'w-1/12',
    },
    {
      name: 'Actions Taken',
      width: 'w-1/8',
    },
    {
      name: '',
      width: 'w-1/20',
    },
  ];

  const sortableColumns = ['Email Date', 'Rem. Date'];

  const getFallback = () => {
    if (!data?.hits?.length) {
      return (
        <td colSpan={headerColumns.length}>
          <NoThreatFallback />
        </td>
      );
    }

    if (isLoading) {
      return (
        <td colSpan={headerColumns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }

    return null;
  };

  const [sortState, setSortState] = useState<Record<string, 'asc' | 'desc' | null>>({
    'Email Date': null,
    'Rem. Date': 'desc',
  });

  const handleSort = (name: string) => {
    setSortState((prevSortState) => {
      const newSortState = { ...prevSortState };

      const isQuarantineDate = name === 'Rem. Date';

      if (isQuarantineDate) {
        newSortState['Email Date'] = null;
      } else {
        newSortState['Rem. Date'] = null;
      }

      if (prevSortState[name] === 'desc') {
        newSortState[name] = 'asc';
      } else {
        newSortState[name] = 'desc';
      }

      onSort(isQuarantineDate ? 'action_at' : 'time', newSortState[name] || '');

      return newSortState;
    });
  };

  return (
    <>
      <table className="w-full bg-white overflow-auto table-fixed">
        <colgroup>
          {headerColumns.map((header) => (
            <col key={header.name} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-sm text-light-grey w-full border-y-light border-y-border-primary">
          <tr>
            {headerColumns.map((header) => {
              const isSortable = sortableColumns.includes(header.name);
              return (
                <th
                  key={header.name}
                  scope="col"
                  className={`py-6 px-3 font-normal text-start ${header.width} cursor-pointer`}
                  onClick={() => (isSortable ? handleSort(header.name) : null)}
                >
                  <div className="inline-flex items-center gap-1">
                    <span>{header.name}</span>
                    {isSortable && <Sort sortOrder={sortState[header.name]} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">
          {isLoading || !data?.hits?.length
            ? getFallback()
            : data?.hits.map((event) => <BlacklistedRow key={event.id} event={event} />)}
        </tbody>
      </table>
      {data?.hits?.length && !isLoading && (
        <div className="flex justify-between px-6 py-4 items-center">
          <span className="text-xs font-light">
            Showing {data && data?.hits?.length > 0 ? offset + 1 : '0'} -{' '}
            {data && data?.hits?.length > 0 ? offset + (data?.hits?.length || 0) : '0'} of{' '}
            {data?.total} events
          </span>
          <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
            <IconArrowDown
              className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${data && offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (data) {
                  if (data.offset > 0) {
                    handlePagination(limit, offset - limit);
                  }
                }
              }}
            />
            <IconArrowDown
              className={`w-6 h-6 -rotate-90 ${data && offset + limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (data && offset + limit < total) {
                  handlePagination(limit, offset + limit);
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
