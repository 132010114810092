import { useState } from 'react';

import Sort from 'components/Sort';
import { RemediatedEventsFilter, RemediatedEventsResults } from 'models/events';
import { NoThreatFallback } from 'components/Threats/NoThreatFallback';
import Loader from 'components/Loader';

import { RemediationRow } from './RemediationRow';

interface RemediationTableProps {
  data?: RemediatedEventsResults;
  isLoading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<RemediatedEventsFilter>>;
}

export function RemediationTable({ data, isLoading, setFilters }: RemediationTableProps) {
  const headerColumns = [
    {
      name: 'Mail Subject',
      width: 'w-1/8',
    },
    {
      name: 'Sender & Recipients',
      width: 'w-1/8',
    },
    {
      name: 'Remediated By',
      width: 'w-1/8',
    },
    {
      name: 'Remediation Reason',
      width: 'w-1/8',
    },
    {
      name: 'Email Date',
      width: 'w-1/10',
    },
    {
      name: 'Remediation Date',
      width: 'w-1/10',
    },
    {
      name: 'Actions Taken',
      width: 'w-1/8',
    },
    {
      name: '',
      width: 'w-1/20',
    },
  ];

  const getFallback = () => {
    if (!data?.hits?.length) {
      return (
        <td colSpan={headerColumns.length}>
          <NoThreatFallback />
        </td>
      );
    }

    if (isLoading) {
      return (
        <td colSpan={headerColumns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }

    return null;
  };

  const [sortState, setSortState] = useState<Record<string, 'asc' | 'desc' | null>>({
    'Email Date': null,
    'Remediation Date': 'desc',
  });

  const handleSort = (name: string) => {
    setSortState((prevSortState) => {
      const newSortState = { ...prevSortState };

      const isQuarantineDate = name === 'Remediation Date';

      if (isQuarantineDate) {
        newSortState['Email Date'] = null;
      } else {
        newSortState['Quarantined Date'] = null;
      }

      if (prevSortState[name] === 'desc') {
        newSortState[name] = 'asc';
      } else {
        newSortState[name] = 'desc';
      }

      setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: isQuarantineDate ? 'action_at' : 'time',
        sortOrder: newSortState[name] as 'asc' | 'desc',
      }));
      return newSortState;
    });
  };

  const sortableColumns = ['Email Date', 'Remediation Date'];

  return (
    <table className="w-full bg-white overflow-auto table-fixed">
      <colgroup>
        {headerColumns.map((header) => (
          <col key={header.name} className={header.width} />
        ))}
      </colgroup>
      <thead className="text-sm text-light-grey w-full border-y-light border-y-border-primary">
        <tr>
          {headerColumns.map((header) => {
            const isSortable = sortableColumns.includes(header.name);
            return (
              <th
                key={header.name}
                scope="col"
                className={`py-6 px-3 font-normal text-start ${header.width} cursor-pointer`}
                onClick={() => (isSortable ? handleSort(header.name) : null)}
              >
                <div className="inline-flex items-center gap-1">
                  <span>{header.name}</span>
                  {isSortable && <Sort sortOrder={sortState[header.name]} />}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="w-full">
        {isLoading || !data?.hits?.length
          ? getFallback()
          : data?.hits.map((event) => (
              <RemediationRow
                key={event.id}
                id={event.id}
                isAutomated={event.isAutomated}
                reason={event.reason}
                event={event.event}
                action={event.action}
                actionBy={event.actionBy}
                createdAt={event.createdAt}
                userRemediation={event.userRemediation}
              />
            ))}
      </tbody>
    </table>
  );
}
