import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Dialog from '@radix-ui/react-dialog';

import { Divider } from 'components/Divider';

import * as Switch from '@radix-ui/react-switch';

import { ReactComponent as IconMonitorMode } from 'assets/icons/monitor-mode.svg';
import { useGetConfigQuery, useUpdateConfigMutation } from 'service/config';
import { useOrgId } from 'hooks/useOrgId';
import Loader from 'components/Loader';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';

interface RemediationSettingsProps {
  navigateToBlockList: () => void;
}
export function RemediationSettings({ navigateToBlockList }: RemediationSettingsProps) {
  const navigate = useNavigate();

  const { showToast } = useToast();

  const [showModal, setShowModal] = useState(false);

  const quickLinks = [
    {
      title: 'Configure Allow/Block Rules',
      actionName: 'Configure',
      onClick: () => navigateToBlockList(),
    },
    {
      title: 'Remediated Mails',
      actionName: 'Review',
      onClick: () => {
        navigate('/remediation');
      },
    },
  ];

  const handleToggleModal = () => {
    setShowModal((prevModal) => !prevModal);
  };

  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetConfigQuery({ orgId: OrgId });

  const getCheckedStatus = () => {
    if (isLoading) return false;

    return data?.applicationMode?.mode === 'prevent';
  };

  const [updateConfig] = useUpdateConfigMutation();

  const handleUpdateConfig = async (checked: boolean) => {
    try {
      await updateConfig({
        orgId: OrgId,
        mode: checked ? 'PREVENT' : 'MANUAL',
      }).unwrap();

      setShowModal(false);

      showToast({
        component: checked ? (
          <SuccessToast message="Prevent mode enabled" />
        ) : (
          <ErrorToast message="Prevent mode disabled" />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again" />,
      });
    }
  };

  const handleConfirm = () => {
    handleUpdateConfig(false);
  };

  const handleChecked = async (checked: boolean) => {
    if (checked) {
      handleUpdateConfig(checked);
    } else {
      setShowModal(true);
    }
  };

  const statusOptions = [
    {
      title: 'Real-Time Alerts',
      value: 'Enabled',
    },
    {
      title: 'Mail Remediation',
      value: getCheckedStatus() ? 'Automatic' : 'Manual',
    },
    {
      title: 'Block / Allow Rules',
      value: getCheckedStatus() ? 'Enabled' : 'Disabled',
    },
  ];

  return (
    <>
      <div className="px-10 py-8 w-full">
        <div className="flex items-center gap-6">
          <span className="text-xl">Prevent Mode</span>
          {isLoading ? (
            <Loader />
          ) : (
            <Switch.Root
              checked={getCheckedStatus()}
              className="relative h-6 w-10 cursor-pointer rounded-full outline-none focus:shadow-black data-[state=checked]:bg-gradient-to-r bg-border-primary from-gradient-yellow to-gradient-green border border-border-primary"
              onCheckedChange={handleChecked}
            >
              <Switch.Thumb className="border border-border-primary block size-5 translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-4" />
            </Switch.Root>
          )}
        </div>
        <Divider customCss="my-4" />
        <div
          className={`w-3/5 ${getCheckedStatus() ? 'bg-gradient-to-b from-[#EAFFF7] to-soft-gray' : 'bg-gradient-to-b from-[#FFF2F0] to-soft-gray'} py-3.5 px-7 rounded-lg flex items-center justify-between gap-4`}
        >
          <div className="flex flex-col">
            <p className="text-sm text-black font-semibold">
              Prevent mode is {getCheckedStatus() ? 'on' : 'off'}.
            </p>
            <p className="text-sm text-light-grey">
              {getCheckedStatus()
                ? 'Threats will be automatically handled based on your configured rules. Block/Allow list rules are active.'
                : 'Threats will be flagged for manual review. Block/Allow list rules are inactive.'}
            </p>
          </div>
          {!getCheckedStatus() ? (
            <button
              type="button"
              className="bg-black text-white rounded-lg px-4 py-2 text-center"
              onClick={() => handleUpdateConfig(true)}
            >
              Turn on
            </button>
          ) : null}
        </div>
        <div className="mt-12">
          <span>Status Summary</span>
          <Divider />
          <div className="w-1/2 flex flex-col gap-6">
            {statusOptions.map((status) => (
              <div className="w-full flex items-center justify-between">
                <span className="font-medium">{status.title}</span>
                <button
                  type="button"
                  className={`min-w-24 ${['Automatic', 'Enabled'].includes(status.value) ? 'bg-soft-green' : 'bg-soft-gray'} px-2 py-1 rounded-md border-border-primary border-light`}
                >
                  {status.value}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-24">
          <span>Quick Links</span>
          <Divider customCss="my-6" />
          <div className="w-1/2 flex flex-col gap-6">
            {quickLinks.map((link) => (
              <div className="w-full flex items-center justify-between">
                <span className="font-medium">{link.title}</span>
                <button
                  type="button"
                  className="min-w-24 text-center px-2 py-1 bg-black text-white rounded-md"
                  onClick={() => link.onClick()}
                >
                  {link.actionName}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Dialog.Root open={showModal} onOpenChange={handleToggleModal}>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-20" onClick={handleToggleModal} />
        <Dialog.Content className="fixed top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-1/2 w-1/4 bg-white border-light border-border-primary rounded-md z-30">
          <div className="w-full flex flex-col items-center gap-6 py-6 px-10 border-b-light border-b-border-primary">
            <IconMonitorMode className="w-24 h-24" />

            <div className="flex flex-col gap-4 text-center">
              <h3 className="text-xl">Are you sure you want to turn off prevent mode?</h3>
              <p className="text-light-grey">The following changes will take place</p>
            </div>
          </div>
          <div className="flex flex-col gap-6 w-full px-14 py-6">
            <div className="flex items-center justify-between">
              <span>Mail Remediation</span>
              <button
                type="button"
                className="border-light border-border-primary rounded-md p-2 bg-soft-gray"
              >
                Manual
              </button>
            </div>
            <div className="flex items-center justify-between">
              <span>Block / Allow Rules</span>
              <button
                type="button"
                className="border-light border-border-primary rounded-md p-2 bg-soft-gray"
              >
                Disabled
              </button>
            </div>
          </div>
          <Divider customCss="my-1 w-full" />
          <div className="py-6 px-10 w-full flex flex-col gap-2 items-center">
            <button
              type="button"
              className="w-full bg-black text-white text-center rounded-lg py-4"
              onClick={() => handleConfirm()}
            >
              Confirm
            </button>
            <button
              type="button"
              className="bg-white border-b-light border-light-grey text-light-grey"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
