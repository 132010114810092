import { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';

import { Event } from 'models/events';

import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';
import { ReactComponent as IconRemediationAlert } from 'assets/icons/remediation-alert.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info-icon.svg';

import Loader from 'components/Loader';

import { useUndoRemediationMutation } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { UNDO_MOVE_TO_JUNK, UNDO_QUARANTINE_MESSAGE } from 'constants/remediationActions';
// import { Analysis } from 'components/EmailAttributes/Analysis';
import { MailAnalysis } from 'components/Events/MailAnalysis';
import { dateUtils } from 'utils/time';
import { Divider } from 'components/Divider';

interface RemediateOverviewProps {
  event: Event | undefined;
  remediateDate: string;
  open: boolean;
  action: string;
  onClose: () => void;
  isLoading: boolean;
}

export function RemediateOverview({
  open,
  onClose,
  event,
  action: actionOnEvent,
  isLoading,
  remediateDate,
}: RemediateOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    moveToInbox: false,
  });

  const [loading, setLoading] = useState(false);

  const handleToggle = (action: 'moveToInbox') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const [undoRemediation] = useUndoRemediationMutation();

  const [OrgId] = useOrgId();

  const handleUndoRemediate = async (e: React.MouseEvent) => {
    if (!checkedStateOfActions.moveToInbox || loading) {
      return;
    }

    e.stopPropagation();
    setLoading(true);

    try {
      await undoRemediation({
        orgId: OrgId,
        messageId: event?.messageId || '',
        action: actionOnEvent === 'MOVE_TO_JUNK' ? UNDO_MOVE_TO_JUNK : UNDO_QUARANTINE_MESSAGE,
      }).unwrap();

      showToast({
        component: (
          <ActionToast description="Moved message to 'Inbox'" showUndo={false} showAvatar={false} />
        ),
      });
      onClose();
    } catch {
      showToast({
        component: (
          <ErrorToast message='Failed to move message to "Inbox", please try again later' />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  if (!event || isLoading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-2/3 h-overview-modal  bg-white rounded-lg">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4 "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full max-h-overview-modal bg-white rounded-3xl flex justify-center">
            {/* <Analysis event={event} showFeedback={false} /> */}
            <MailAnalysis event={event} showFeedback={false} />
            <div className="w-2/5 flex flex-col gap-8 justify-between">
              <div>
                <div className="py-10 px-12 border-b-light border-border-primary">
                  <div className="w-full flex items-center gap-4">
                    <IconRemediationAlert className="w-7 h-7" />
                    <span className="text-light-grey text-xl">Mail Remediated</span>
                    <button
                      type="button"
                      className="py-0.5 px-2 border-light border-border-primary bg-soft-gray text-light-grey rounded-md text-sm w-fit"
                    >
                      {dateUtils.format(remediateDate || '', 'DD/MM/YYYY, HH:mm A').toString()}
                    </button>
                  </div>
                </div>
                <div className="py-10 px-12 flex flex-col gap-8">
                  <div className="w-full flex justify-between gap-4">
                    <div className="w-6 h-4 flex items-center justify-center rounded bg-border-primary mt-2">
                      <IconCross className="w-2 h-2 cursor-not-allowed" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-base">Undo Log Out Recipient & Reset Password</span>
                      <span className="text-sm text-light-grey">
                        This action cannot be reversed. Recipient will continue to be signed out of
                        all active sessions.
                      </span>
                    </div>
                  </div>
                  <Divider customCss="my-0" />
                  {event.status !== 'Pending' ? (
                    <>
                      <div className="w-full flex justify-between gap-4">
                        <div className="mt-2">
                          <Checkbox.Root
                            className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                            checked={checkedStateOfActions.moveToInbox}
                            onClick={() => handleToggle('moveToInbox')}
                          >
                            <Checkbox.Indicator>
                              <IconCheck />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                        </div>
                        <button
                          className="flex flex-col gap-1 cursor-pointer"
                          onClick={() => handleToggle('moveToInbox')}
                          type="button"
                        >
                          <span className="text-base">Push Mail back to Inbox</span>
                          <span className="text-sm text-light-grey text-start">
                            This email will be moved back to the user&apos;s inbox from the
                            Quarantine.
                          </span>
                        </button>
                      </div>
                      <Divider customCss="my-0" />
                    </>
                  ) : null}
                </div>
              </div>
              <div className="py-10 px-12 flex flex-col gap-8 border-t-light border-border-primary">
                <div className="flex items-center gap-4">
                  {' '}
                  <IconInfo className="w-6 h-6" />{' '}
                  <span className="text-xs text-light-grey">
                    Retention of Quarantined/ Junk mails is managed by your mail service provider
                  </span>
                </div>
                <button
                  className={`w-full ${checkedStateOfActions.moveToInbox ? 'bg-black' : 'bg-black/20'} rounded-3xl text-white p-4 text-center text-base ${checkedStateOfActions.moveToInbox ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                  type="button"
                  onClick={handleUndoRemediate}
                >
                  {loading ? <Loader color="white" /> : 'Restore Mail'}
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
