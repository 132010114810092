import {
  validateIPv4,
  validateIPv6,
  validateDomains,
  validateEmail,
  ValidationResult,
} from 'utils/validation';

export function validateInput(input: string): ValidationResult {
  const ipV4Result = validateIPv4(input);
  const ipV6Result = validateIPv6(input);
  if (ipV4Result.isValid || ipV6Result.isValid) {
    return ipV4Result.isValid ? ipV4Result : ipV6Result;
  }

  const emailResult = validateEmail(input);
  if (emailResult.isValid) {
    return emailResult;
  }

  const domainResult = validateDomains(input);
  if (domainResult.isValid) {
    return domainResult;
  }

  return { isValid: false, error: 'Input matches no valid format (email/ip/domain)' };
}
