import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useOrgId } from 'hooks/useOrgId';
import { RemediatedEventsFilter } from 'models/events';
import { useGetRemediatedEventsQuery, useGetBlacklistedEventsQuery } from 'service/eventsApi';
import { useQueryParam } from 'hooks/useQueryParam';
import { dateUtils } from 'utils/time';

import { RemediationTable } from './RemediationTable';
import { BlacklistedTable } from './BlacklistedEvents';

interface RemediatedEventsProps {
  filter: RemediatedEventsFilter;
  setFilter: React.Dispatch<React.SetStateAction<RemediatedEventsFilter>>;
}

export function RemediatedEvents({ filter, setFilter }: RemediatedEventsProps) {
  const [OrgId] = useOrgId();

  const [value, setValue] = useQueryParam({ key: 'tab' });

  const [tab, setTab] = useState<'remediated' | 'blocked'>('remediated');

  useEffect(() => {
    if (value !== '') {
      setTab(value as 'remediated' | 'blocked');
    }
  }, [value]);

  const { data, isLoading } = useGetRemediatedEventsQuery(
    {
      orgId: OrgId,
      limit: filter.limit,
      offset: filter.offset,
      sortBy: filter.sortBy,
      sortOrder: filter.sortOrder,
      from: filter.from,
      to: filter.to,
      isAutomated: false,
    },
    {
      skip: tab === 'blocked',
    }
  );

  const { data: blacklistedData, isLoading: isBlacklistLoading } = useGetBlacklistedEventsQuery(
    {
      orgId: OrgId,
      limit: filter.limit,
      offset: filter.offset,
      from: filter.from || '',
      to: filter.to || '',
      sortBy: filter.sortBy || '',
      sortOrder: filter.sortOrder || '',
    },
    {
      skip: tab === 'remediated',
    }
  );

  const handleOnTabClick = (chosedTab: 'remediated' | 'blocked') => {
    setTab(chosedTab);
    setValue(chosedTab);

    setFilter({
      limit: 10,
      offset: 0,
      from: dateUtils.subtract(new Date().toDateString(), 6, 'd').toDate().toISOString(),
      to: new Date().toISOString(),
      sortBy: 'time',
      sortOrder: 'desc',
      threat: '',
      isAutomated: false,
    });
  };

  const handlePagination = (limit: number, offset: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, limit, offset }));
  };

  const handleSorting = (sortBy: string, sortOrder: string) => {
    setFilter((prevFilter) => ({ ...prevFilter, sortBy, sortOrder }));
  };

  return (
    <div className="mt-4 w-full">
      <div className="overflow-hidden w-full">
        <div className="w-full relative">
          <div className="w-full overflow-auto px-2">
            <div className="pb-8 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <button
                  type="button"
                  className={`${tab === 'remediated' ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full px-4 py-2 text-sm border-light border-border-primary flex items-center gap-2`}
                  onClick={() => handleOnTabClick('remediated')}
                >
                  Remediated
                </button>
                <button
                  type="button"
                  className={`${tab === 'blocked' ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full px-4 py-2 text-sm border-light border-border-primary flex items-center gap-2`}
                  onClick={() => handleOnTabClick('blocked')}
                >
                  Blocked
                </button>
              </div>
            </div>
            {tab === 'remediated' ? (
              <RemediationTable data={data} isLoading={isLoading} setFilters={setFilter} />
            ) : (
              <BlacklistedTable
                data={blacklistedData}
                isLoading={isBlacklistLoading}
                total={blacklistedData?.total || 0}
                limit={filter.limit}
                offset={filter.offset}
                handlePagination={(limit, offset) => handlePagination(limit, offset)}
                onSort={(sortBy, sortOrder) => handleSorting(sortBy, sortOrder)}
              />
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
